import Flickity from "flickity";

window.initializeProductCarousel = () => {
  // Loop through and set up any product carousels
  // (product page, landing page, the fold)
  const carousels = document.querySelectorAll(".products-carousel");

  carousels.forEach(carousel => {
    const isFold = !!carousel.closest(".article-products-carousel");
    const options = {
      pageDots: true,
      contain: true,
      cellAlign: isFold ? "center" : "left",
      groupCells: true,
      imagesLoaded: true,
      cellSelector: ".carousel-cell",
      // These events work around ios 11.3+ bug
      // https://github.com/metafizzy/flickity/issues/740
      on: {
        dragStart() {
          document.ontouchmove = e => {
            e.preventDefault();
          };
        },

        dragEnd() {
          document.ontouchmove = () => {
            return true;
          };
        }
      }
    };

    const flkty = new Flickity(carousel, options);

    flkty.on("resize", () => {
      const isSingleSlide = flkty.slides.length < 2;
      carousel.classList.toggle("is-single-slide", isSingleSlide);
    });

    // Trigger a resize event to
    flkty.resize();
  });
};

window.initializeProductCarousel();
